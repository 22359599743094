import { gql } from "@apollo/client";
import client from "services/api-graphql";

export const CREATE_PROFESSIONAL_REPORT = gql`
    query ProfessionalReport($input: GetProfessionalReportInputType!) {
        professionalReport(input: $input) {
            grossEarning
            grossPixPayment
            grossCreditCardPayment
            averageTicket
            calendarDays
            operationalDays
            amountOfAppointments
            discounts {
            key
            value
            multiplier
            discount
            }
            totalDiscount
            otherValues {
            description
            amount
            }
            earningAfterDiscount
            totalOtherValues
            netEarning
            netPixPayment
            netCreditCardPayment
            totalWithoutZazuuTax
            appointmentsData {  
                date                
                customer
                pet
                serviceName
                price
            }
            fines {
              description
              amount
            }
            bonus {
              description
              amount
            }
        }
    }
`;

export const GET_REPORT_COST = gql`
  query ReportCosts {
    reportCosts {
      id
      water
      gas
      generator_oil
      created_at
      updated_at
      is_active
      created_by
    }
}
`;

export const SET_REPORT_COST = gql`
    mutation UpdateReportCost($input: ReportCostInputType!) {
    updateReportCost(input: $input) {
      id
      water
      gas
      generator_oil
      created_at
      updated_at
      is_active
      created_by
    }
  }
`;


export const listOptionProfessional = async (input: string) => {
  const variables = {
    filters: {
      pagination: {
        limit: 6,
        page: 1
      },
      where: {
        name: input,
        status: true
      }
    }
  };
  const { data } = await client.query({
    query: gql`
        query Professionals($filters: FiltersProfessionalInputType) {
            professionals(filters: $filters) {
                id
                user {
                    name
                    roles {
                      id
                    }
                }
            }
        }
      `,
    variables,
    fetchPolicy: 'network-only',
  });
  return data.professionals
};