import { ApolloError, useQuery } from '@apollo/client';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { FormField, FormRow } from 'components/Forms/styles';
import { Label } from 'components/Label/styles';
import Select from 'components/Select';
import TextInput from 'components/TextInput';
import { format } from 'date-fns';
import { GET_TRUCKS } from 'pages/StockTrucks/constants/graphQL';
import { ITruckBase } from 'pages/StockTrucks/constants/types';
import { GET_WAREHOUSES } from 'pages/StockWarehouses/constants/graphQL';
import { IWarehousesResponse } from 'pages/StockWarehouses/constants/types';
import React, { useCallback, useMemo, useRef, useState } from 'react';
import getValidationErrors from 'shared/utils/getValidationErrors';
import { launchToast } from 'shared/utils/launchToast';
import { ISelectOption } from 'types/ISelectValue';
import { ValidationError } from 'yup';
import { defaultSelectedRecipient } from '../constants';
import { IMoveProductFormData, IMoveProductFormProps } from '../constants/types';
import { MoveProductFormId, MoveProductValidationSchema } from './constants';

function MoveProductForm({
  onSubmit,
  product,
  onClose,
}: IMoveProductFormProps) {
  const [recipientType, setRecipientType] = useState<'TRUCK' | 'WAREHOUSE'>('TRUCK');
  const [selectedRecipient, setSelectedRecipient] = useState(defaultSelectedRecipient);  

  const moveProductFormRef = useRef<FormHandles>(null);

  const { data: warehousesList } = useQuery<{ warehouses: IWarehousesResponse[] }>(GET_WAREHOUSES);
  const { data: truckList } = useQuery<{ trucks: ITruckBase[] }>(GET_TRUCKS);

  const validateFields = useCallback(async (
    payload: IMoveProductFormData,
    options: { reset: () => void }
  ) => {
    try {
      payload.recipientId = selectedRecipient.value;
      payload.quantity = Number(payload.quantity);
      await MoveProductValidationSchema.validate(payload, { abortEarly: false });
      await onSubmit({
        purchasedProductId: product.id,
        movementDetail: {
          ...payload,
          sourceType: product.local.type,
        }
      });
      options.reset();
      launchToast("Produto movimentado com sucesso", "success");
      onClose();
    } catch (error) {
      if (error instanceof ValidationError) {
        const errors = getValidationErrors(error);
        moveProductFormRef.current?.setErrors(errors);
      } else if (error instanceof ApolloError) {
        launchToast(error.message, "error");
      } else {
        launchToast("Erro desconhecido. Contate o suporte", "error");
      }
    }
  }, [
    selectedRecipient,
    MoveProductValidationSchema,
    onSubmit,
    product,
    moveProductFormRef.current,
    getValidationErrors,
    launchToast,
  ]);

  const recipientOptions = useMemo(() => {
    if (recipientType === 'WAREHOUSE' && warehousesList) {
      const warehouseOptions = warehousesList.warehouses.map((warehouse) => ({
        value: warehouse.id,
        label: warehouse.name
      }));
      setSelectedRecipient(defaultSelectedRecipient);
      return [...warehouseOptions];
    } else if (recipientType === 'TRUCK' && truckList) {
      const truckOptions = truckList.trucks.map((truck) => ({
        value: truck.id,
        label: truck.name
      }));
      setSelectedRecipient(defaultSelectedRecipient);
      return truckOptions;
    } else {
      return [];
    }
  }, [
    recipientType,
    warehousesList,
    truckList,
    defaultSelectedRecipient
  ]);

  return (
    <Form
      onSubmit={validateFields}
      ref={moveProductFormRef}
      id={MoveProductFormId}
    >
      <FormRow>
        <FormField>
          <Label>origem</Label>
          <Select
            name="sourceId"
            isDisabled
            value={{
              value: product.local.id,
              label: product.local.name,
            }}
          />
        </FormField>
        <FormField>
          <Label>produto</Label>
          <Select
            name="receiptProductId"
            isDisabled
            value={{
              value: product.receiptProduct.id,
              label: product.receiptProduct.product.name,
            }}
          />
        </FormField>
        <TextInput
          label="data da movimentação"
          name="movementDate"
          type="date"
          defaultValue={format(new Date(), "yyyy-MM-dd")}
        />
      </FormRow>
      <FormRow>
        <FormField>
          <Label>truck ou armazem</Label>
          <Select
            name="recipientType"
            options={[
              { value: 'TRUCK', label: 'Truck' },
              { value: 'WAREHOUSE', label: 'Armazem' }
            ]}
            onChange={(option) => setRecipientType(option?.value)}
            defaultValue={{ value: 'TRUCK', label: 'Truck' }}
          />
        </FormField>
        <FormField>
          <Label>destino</Label>
          <Select
            name="recipientId"
            options={recipientOptions}
            placeholder="Selecione um destino"
            value={selectedRecipient}
            onChange={(option) => setSelectedRecipient(option as ISelectOption)}
          />
        </FormField>
        <TextInput
          label="quantidade"
          name="quantity"
          type="number"
        />
      </FormRow>
    </Form>
  )
}

export default MoveProductForm;
