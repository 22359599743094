import { useQuery } from "@apollo/client";
import { useDisclosure } from "@chakra-ui/react";
import Loading from "components/Loading";
import MainContainer from "components/MainContainer";
import Pagination from "components/Pagination";
import { PanelHeader } from "components/PanelHeader";
import Table from "components/Table";
import { format, parseISO } from "date-fns";
import React, { useCallback, useState } from "react";
import { IoMdEye } from "react-icons/io";
import { useHistory } from "react-router-dom";
import { ReactComponent as EditButton } from "../../assets/svg/generic/edit.svg";
import { columnReceiptsHeaders } from "./constants";
import { GET_RECEIPTS } from "./constants/graphQL";
import { IExtendedReceipt, IReceiptsResponse } from "./constants/types";
import UpdateReceiptsModal from "./modals/UpdateReceiptsModal";
import convertToCurrencyFormat from "shared/utils/convertToCurrencyFormat";

const Receipts: React.FC = () => {
	const [paginationLimit] = useState<number>(10);
	const [selectedReceipt, setSelectedReceipt] = useState<IExtendedReceipt | null>(null);
	const [currentPage, setCurrentPage] = useState<number>(1);

	const updateReceiptsModalControl = useDisclosure();

	const history = useHistory();

	const handleReceiptUpdate = useCallback(
		async (receipt: IExtendedReceipt) => {
			setSelectedReceipt(receipt);
			updateReceiptsModalControl.onOpen();
		},
		[updateReceiptsModalControl]
	);

	const { data, loading } = useQuery<IReceiptsResponse>(GET_RECEIPTS, {
		variables: {
			filterParams: { },
			paginationParams: {
				limit: paginationLimit,
				page: currentPage,
			},
			orderParams: {
				field: "emissionDate",
				order: "desc",
			},
		},
	});

	return (
		<>
			{selectedReceipt && (
				<UpdateReceiptsModal
					isOpen={updateReceiptsModalControl.isOpen}
					onClose={updateReceiptsModalControl.onClose}
					receipt={selectedReceipt}
				/>
			)}
			<MainContainer>
				<PanelHeader
					title="Notas Fiscais"
					isNew
					isFilterModalVisible={false}
					onClick={() => history.push('/receipts/create')}
				/>
				{loading && <Loading />}
				{data && (
					<>
						<Table
							headers={columnReceiptsHeaders}
							data={data.receipts.data.map((receipt) => ({
								...receipt,
								emissionDate: format(parseISO(receipt.emissionDate), "dd/MM/yyyy"),
								totalPrice: convertToCurrencyFormat((receipt.totalPrice as number) / 100),
								actions: [
									{
										name: receipt.status === "Pendente"
											? "Dar baixa"
											: receipt.status === "Divergente"
												? "Editar"
												: "Visualizar",
										icon: receipt.status === "Pendente" || receipt.status === "Divergente"
											? <EditButton />
											: <IoMdEye size={20} />,
										action: () => handleReceiptUpdate(receipt),
									},
								],
							}))}
						/>
						<Pagination
							totalPage={data.receipts.totalPages}
							pageIndex={currentPage}
							setPage={setCurrentPage}
						/>
					</>
				)}
			</MainContainer>
		</>
  )
}

export default Receipts
