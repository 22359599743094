import * as Yup from 'yup';

export const CreateProfessionalReportFormValidationSchema = Yup.object().shape({
    from: Yup.string()
        .required('Data inicial é obrigatória'),
    to: Yup.string()
        .required('Data final é obrigatória'),
    companyComission: Yup.string()
        .required('Porcentagem de comissão é obrigatória'),
    professional: Yup.string()
        .required('Profissional obrigatório'),
    groomingSupplies: Yup.string()
        .required('Valor dos suplemento é obrigatório'),
    equipmentValue: Yup.string()
        .required('Valor do aluguel de equipamentos é obrigatório'),
    blueZoneValue: Yup.string()
        .required('Valor gasto com Zona Azul é obrigatório'),
    blueZoneQuantity: Yup.string()
        .required('Quantidade de dias de equipamentos alugados é obrigatória'),
    parkingValue: Yup.string()
        .required('Valor gasto com estacionamento é obrigatória'),
    parkingQuantity: Yup.string()
        .required('Quantidade de dias de equipamentos alugados é obrigatória'),
});

export const extraFildsValidation = Yup.object().shape({
    amount: Yup.string()
        .required('Valor é obrigatório'),
    description: Yup.string()
        .required('Descrição é obrigatória'),
});

export const UpdateReportCostFormValidationSchema = Yup.object().shape({
    waterValue: Yup.string()
        .required('Valor da água é obrigatório'),
    gasValue: Yup.string()
        .required('Valor do gás é obrigatório'),
    oilValue: Yup.string()
        .required('Valor do óleo é obrigatório'),
});


