export const receiptProductsHeaderTable = [
    { key: "product", name: "Produto" },
    { key: "stockUnit", name: "Medida" },
    { key: "quantity", name: "Quantidade" },
    { key: "unityPrice", name: "Preço de compra" },
    { key: "resalePrice", name: "Preço de venda" },
    { key: "actions", name: "ações" },
];

export const columnReceiptsHeaders = [
    { key: "id", name: "Id" },
    { key: "number", name: "Numero" },
    { key: "supplier", name: "Fornecedor" },
    { key: "emissionDate", name: "Data de Emissão" },
    { key: "totalPrice", name: "Valor total" },
    { key: "status", name: "status" },
    { key: "actions", name: "ações" },
];
