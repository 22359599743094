const convertToCurrencyFormat = (value: string | number, precision: number = 2): string => {
  return new Intl.NumberFormat("pt-BR", {
    style: "currency",
    currency: "BRL",
    minimumFractionDigits: precision,
    maximumFractionDigits: precision
  }).format(value as number);
};

export default convertToCurrencyFormat;
