import pdfMake from 'pdfmake/build/pdfmake.js';
import pdfFonts from 'pdfmake/build/vfs_fonts.js';
import { formatDate, priceMask, priceUnmask, toBase64 } from './professionalReportUtils';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import { veterinarianReportExcel } from './veterinarianReportExcel';

pdfMake.vfs = pdfFonts.pdfMake.vfs;

type Discount = {
  value?: number;
  discount?: string;
  multiplier?: number;
};

type OtherExpense = {
  description: string;
  amount: number;
};

type AppointmentData = {
  date: string;
  customer: string;
  pet: string;
  price: string;
  serviceName: string
};

type ProfessionalReportData = {
  grossEarning: number;
  grossPixPayment: number;
  grossCreditCardPayment: number;
  averageTicket: number;
  calendarDays: number;
  operationalDays: number;
  amountOfAppointments: number;
  discounts: Discount[];
  totalDiscount: string;
  earningAfterDiscount: string;
  totalOtherValues: string;
  netEarning: string;
  netPixPayment: string;
  netCreditCardPayment: string;
  totalWithoutZazuuTax: string;
  appointmentsData: AppointmentData[];
  fines: OtherExpense[];
  bonus: OtherExpense[];
};

type GeneratePdfParams = {
  logoPath: string;
  payload: {
    professional: {
      label: string;
      value: string;
    };
    from: Date;
    to: Date;
    otherExpenses?: OtherExpense[];
  };
  data: {
    professionalReport: ProfessionalReportData;
  };
  finantial: boolean;
};

export const generateProfessionalVeterianrianReportPdf = async ({
  logoPath,
  payload,
  data,
  finantial,
}: GeneratePdfParams) => {
  const { professionalReport } = data;
  const { professional, from, to, otherExpenses = [] } = payload;
  const { discounts = [], appointmentsData = [], fines = [], bonus = []  } = professionalReport;

  const discountRows = [
    [
      { text: 'Descrição', style: 'tableHeader' },
      { text: 'Valor', style: 'tableHeader' },
      { text: 'Desconto', style: 'tableHeader' },
    ],
    ...(finantial
      ? [
        [
          'Comissão da Empresa',
          discounts[1]?.value ?? '',
          priceMask(discounts[1]?.discount ?? '0' ),
        ],
      ]
      : []),
    [
      'Taxa de Adquirência',
      discounts[2]?.value ?? '',
      priceMask(discounts[2]?.discount || '0') ?? '',
    ],
    [
      'Taxa de Adquirência PIX',
      discounts[3]?.value ?? '',
      priceMask(discounts[3]?.discount || '0') ?? '',
    ],
    ['Custo dos Medicamentos e Exame', '', priceMask(discounts[4]?.discount || '0') ?? ''],
  ];

  const fineRows = [
    [
      { text: 'Descrição', style: 'tableHeader' },
      { text: 'Valor', style: 'tableHeader' },
    ],
    ...fines.map((item) => [item.description, priceMask(item.amount.toFixed(2))]),
  ];

  const bonusRows = [
    [
      { text: 'Descrição', style: 'tableHeader' },
      { text: 'Valor', style: 'tableHeader' },
    ],
    ...bonus.map((item) => [item.description, priceMask(item.amount.toFixed(2))]),
  ];

  const otherExpensesRows = [
    [
      { text: 'Descrição', style: 'tableHeader' },
      { text: 'Valor', style: 'tableHeader' },
    ],
    ...otherExpenses.map((item) => [item.description, priceMask(item.amount.toFixed(2))]),
  ];

  const appointmentsTableBody = [
    [
      { text: 'Data', style: 'tableHeader' },
      { text: 'Cliente', style: 'tableHeader' },
      { text: 'Pet', style: 'tableHeader' },
      { text: 'Serviço', style: 'tableHeader' },
      { text: 'Valor a receber', style: 'tableHeader' },
    ],
    ...appointmentsData.map((appt) => [
      formatDate(new Date(appt.date)),
      appt.customer,
      appt.pet,
      appt.serviceName,
      priceMask(parseFloat(appt.price).toFixed(2)),
    ]),
  ];

  const response = await fetch(logoPath);
  const blob = await response.blob();
  const base64Background = await toBase64(blob);

  const docDefinition: any = {
    pageSize: 'A4',
    pageMargins: [30, 100, 30, 140],
    defaultStyle: {
      fontSize: 10,
    },
    images: {
      background: base64Background,
    },
    background: {
      image: 'background',
      width: 595,
      height: 842,
    },
    styles: {
      header: {
        fontSize: 14,
        bold: true,
      },
      subHeader: {
        fontSize: 11,
        bold: true,
        margin: [0, 10, 0, 5],
      },
      tableHeader: {
        bold: true,
        fillColor: '#eeeeee',
      },
      tableSectionTitle: {
        fillColor: '#F8F8F8',
        bold: true,
      },
    },
    content: [
      {
        text: `Resumo Financeiro: ${professional.label}`,
        style: 'header',
        margin: [0, 0, 0, 10],
      },
      {
        columns: [
          {
            text: `Período: ${formatDate(new Date(from))} - ${formatDate(new Date(to))}`,
          },
        ],
        margin: [0, 0, 0, 10],
      },
      {
        style: 'tableSectionTitle',
        table: {
          widths: ['*'],
          body: [[{ text: 'Faturamento', alignment: 'center' }]],
        },
        margin: [0, 0, 0, 5],
      },
      {
        table: {
          widths: ['*', '*'],
          body: [
            ...(finantial
              ? [
                [
                  { text: 'Faturamento Bruto:', bold: true },
                  priceMask(professionalReport?.totalWithoutZazuuTax),
                ],
              ]
              : []),
                [
                  { text: 'Faturamento:', bold: true },
                  priceMask(professionalReport.grossEarning.toFixed(2)),
                ],
          ],
        },
        layout: 'lightHorizontalLines',
        margin: [0, 0, 0, 15],
      },
      {
        text: 'Descontos:',
        style: 'subHeader',
      },
      {
        table: {
          widths: ['*', '*', '*'],
          body: discountRows,
        },
        layout: 'lightHorizontalLines',
        margin: [0, 0, 0, 10],
      },
      {
        table: {
          widths: ['*', '*'],
          body: [
            ...(finantial
              ? [
                [
                  { text: 'Total de Descontos:', bold: true },
                  priceMask(data.professionalReport.totalDiscount),
                ],
              ]
              : []),
            [
              { text: 'Resultado Após Descontos:', bold: true },
              priceMask(professionalReport.earningAfterDiscount),
            ],
          ],
        },
        layout: 'lightHorizontalLines',
        margin: [0, 10, 0, 15],
      },
      {
        text: 'Multas:',
        style: 'subHeader',
      },
      { 
        ... fineRows.length > 1 ? { 
          table: {
            widths: ['*', '*'],
            body: fineRows,
          },
          layout: 'lightHorizontalLines',
          margin: [0, 0, 0, 15],
        } : {
          text: 'sem multas',
          style: 'text',
          margin: [0, 0, 0, 15],
        }
      },
      {
        text: 'Outros Valores:',
        style: 'subHeader',
      },
      {
        table: {
          widths: ['*', '*'],
          body: otherExpensesRows,
        },
        layout: 'lightHorizontalLines',
        margin: [0, 0, 0, 10],
      },
      {
        table: {
          widths: ['*', '*'],
          body: [
            [
              { text: 'Total de Outros Valores:', bold: true },
              priceMask(professionalReport.totalOtherValues),
            ],
            [
              { text: 'Resultado Líquido:', bold: true },
              priceMask(professionalReport.netEarning),
            ],
          ],
        },
        margin: [0, 0, 0, 10],
        layout: 'lightHorizontalLines',
      },
      {
        text: 'Bônus futuros:',
        style: 'subHeader',
      },
      { 
        ... bonusRows.length > 1 ? { 
          table: {
            widths: ['*', '*'],
            body: bonusRows,
          },
          layout: 'lightHorizontalLines',
          margin: [0, 0, 0, 15],
        } : {
          text: 'sem bônus futuro',
          style: 'text',
          margin: [0, 0, 0, 15],
        }
      },
      {
        text: 'Agendamentos',
        style: 'subHeader',
        margin: [0, 10, 0, 5],
      },
      {
        table: {
          widths: ['*', '*', '*', '*', '*'],
          body: appointmentsTableBody,
        },
        layout: 'lightHorizontalLines',
        margin: [0, 0, 0, 10],
      },
    ],
  };

  const fileName = `relatorio_${professional.label}.pdf`;
  const excelBlob = await veterinarianReportExcel({ payload, isVet: true });
  const pdfDocGenerator = pdfMake.createPdf(docDefinition);

  pdfDocGenerator.getBlob(async (pdfBlob: Blob) => {
    try {
      if (!excelBlob) {
        throw new Error('Erro ao gerar excel');
      }
      const zip = new JSZip();
      zip.file(fileName, pdfBlob);
      zip.file(`relatorio_excel_${professional.label}.xlsx`, excelBlob);
      const zipBlob = await zip.generateAsync({ type: 'blob' });
      saveAs(zipBlob, `relatorio_${professional.label}_${finantial ? 'to_finantial' : 'to_professional'}.zip`);
    } catch (err: any) {
      throw new Error(err.message);
    }
  });
};
