import { ApolloError } from '@apollo/client';
import { FormHandles, Scope } from '@unform/core';
import { Form } from "@unform/web";
import { FormField, FormRow } from 'components/Forms/styles';
import { Label } from 'components/Label/styles';
import Select from 'components/Select';
import TextInput from 'components/TextInput';
import { format, parseISO } from 'date-fns';
import { IUpdateReceiptFormData, IUpdateReceiptsFormProps } from 'pages/Receipts/constants/types';
import { IProductStockUnitTranslation } from 'pages/StockProducts/constants/types';
import React, { ChangeEvent, useEffect, useRef } from 'react';
import getValidationErrors from 'shared/utils/getValidationErrors';
import { launchToast } from 'shared/utils/launchToast';
import { ValidationError } from 'yup';
import { EditProductQuantityValidationSchema, UpdateReceiptFormId } from './constants';
import convertToCurrencyFormat from 'shared/utils/convertToCurrencyFormat';

const UpdateReceiptsForm: React.FC<IUpdateReceiptsFormProps> = ({
	onSubmit,
	receipt,
	onQuantityChange,
	onClose,
}) => {
  const updateReceiptsFormRef = useRef<FormHandles>(null);
    
	useEffect(() => {
		updateReceiptsFormRef.current?.setErrors({});
		updateReceiptsFormRef.current?.setData({
			receipt: {
				...receipt,
				emissionDate: format(parseISO(receipt.emissionDate), "yyyy-MM-dd"),
				reason: '',
				products: receipt.receiptProducts.map((receiptProduct) => {
					return {
						id: {
							label: receiptProduct.product.id,
							value: receiptProduct.product.id
						},
						name: {
							label: receiptProduct.product.name,
							value: receiptProduct.product.name
						},
						stockUnit: {
							label: IProductStockUnitTranslation[receiptProduct.product.stockUnit],
							value: receiptProduct.product.stockUnit,
						},
				}}),
			},
		});
	}, [receipt]);

	const handleQuantityChange = (event: ChangeEvent<HTMLInputElement>, receiptProductId: number) => {
		const updateReceiptProducts = receipt.receiptProducts.map((originalReceiptProduct) => {
			if (originalReceiptProduct.id === receiptProductId) {
				return { ...originalReceiptProduct, quantity: Number(event.target.value) };
			}
			return originalReceiptProduct;
		});
		onQuantityChange(updateReceiptProducts);
	};

	const handleSubmitValidations = async (payload: IUpdateReceiptFormData) => {
		try {
			payload.receipt.products = payload.receipt.products.map((product) => ({ ...product, quantity: Number(product.quantity) }));
			await EditProductQuantityValidationSchema.validate(payload, { abortEarly: false });
			await onSubmit(payload.receipt.products);
			launchToast("Nota atualizada com sucesso", "success");
			onClose();
		} catch (error) {
      if (error instanceof ValidationError) {
        const errors = getValidationErrors(error);
        updateReceiptsFormRef.current?.setErrors(errors);
      } else if (error instanceof ApolloError) {
        launchToast(error.message, "error");
      } else {
        launchToast("Erro desconhecido. Contate o suporte", "error");
      }
		}
	};

	return (
		<Form
			onSubmit={handleSubmitValidations}
			ref={updateReceiptsFormRef}
			id={UpdateReceiptFormId}
		>
			<Scope path="receipt">
				<FormRow>
					<TextInput
						label="numero"
						name="number"
						type="number"
						placeholder="Digite o numero da NF"
						disabled
					/>
					<TextInput
						label="data da emissão"
						name="emissionDate"
						type="date"
						disabled
					/>
					<TextInput
						label="previsão de entrega"
						name="estimatedDeliveryDate"
						type="date"
						disabled
					/>
				</FormRow>
				<FormRow style={{ justifyContent: 'center' }}>
					<FormField style={{ width: 'auto' }}>
						<Label>fornecedor</Label>
						<Select
							name="supplier"
							placeholder="Selecione um fornecedor"
							value={{
								value: receipt.supplier.id,
								label: receipt.supplier.name,
							}}
							isDisabled
						/>
					</FormField>
					<FormField style={{ width: 'auto' }}>
						<Label>destino</Label>
						<Select
							name="warehouse"
							placeholder="Selecione um destino"
							value={{
								value: receipt.warehouse.id,
								label: receipt.warehouse.name,
							}}
							isDisabled
						/>
					</FormField>
					<TextInput
							label="valor total da nota"
							name="totalPrice"
							value={convertToCurrencyFormat(receipt.totalPrice as number / 100)}
							disabled
					/>
					{/* <FormField>
						<Label>Motivo</Label>
						<TextArea
								containerStyle={{ width: "33rem" }}
								name="reason"
								placeholder="Motivo da edição"
						/>
					</FormField> */}
				</FormRow>
					{receipt.receiptProducts.map((receiptProduct, index) => (
						<Scope path={`products[${index}]`}>
							<FormRow>
								<FormField style={{display: 'none'}}>
									<Label>Produtos</Label>
									<Select
										name="id"
									/>
								</FormField>
								<FormField>
									<Label>Produtos</Label>
									<Select
										name="name"
										value={{
											label: receiptProduct.product.name,
											value: receiptProduct.product.name
										}}
										isDisabled
										/>
								</FormField>
								<FormField>
									<Label>unidade de medida</Label>
									<Select
										name="stockUnit"
										value={{
											label: IProductStockUnitTranslation[receiptProduct.product.stockUnit],
											value: receiptProduct.product.stockUnit
										}}
										isDisabled
									/>
								</FormField>
								<TextInput
									label="quantidade"
									name="quantity"
									onChange={(event) => handleQuantityChange(event, receiptProduct.id)}
									defaultValue={receiptProduct.quantity}
									type="number"
								/>
							</FormRow>
						</Scope>
					))}
			</Scope>
		</Form >
	)
}

export default UpdateReceiptsForm;
