import Modal from 'components/Modal';
import React, { useRef, useState } from 'react';
import * as Styles from './constants/styles';
import Text from 'components/Text';
import colors from 'shared/utils/constants/colors';
import Button from "components/Button";
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import Select from 'components/Select';
import { CREATE_PROFESSIONAL_REPORT } from './graphql/ProfessionalReportQuery';
import { useLazyQuery } from '@apollo/client';
import { ICreateProfessionalReportFormData } from './constants/types';
import { priceUnmask } from './professionalReportUtils';
import { generateProfessionalReportPdf } from './professionalReportPDF';
import backgroundPath from 'assets/backdroudpdf.png';
import { launchToast } from 'shared/utils/launchToast';
import { generateProfessionalVeterianrianReportPdf } from './professionaVeterinarianReportPDF';


export type ICreateSectionModalProps = {
    isOpen: boolean;
    onClose: () => void;
    payload: ICreateProfessionalReportFormData
};

const SelectProfessionalModal: React.FC<ICreateSectionModalProps> = ({ isOpen, onClose, payload }) => {
  
    const [selectedProfessionalReport, setSelectedProfessionalReport] = useState([] as any)
    const [loading, setLoading] = useState(false)

    const SelectProfessionalReportFormId = 'select-professional-report-form';
    const selectProfessionalReportFormRef = useRef<FormHandles>(null);

    const options = [{label: 'Relatório para o profissional', value: 1}, {label: 'Relatório para o financeiro', value: 2}]

    const [getProfessionalReport] = useLazyQuery(CREATE_PROFESSIONAL_REPORT);

    const geratePDFToProfessional = async( veterinarian: boolean ) => {
      setLoading(true)
      try {
        const { data } = await getProfessionalReport({
          variables: {
            input: {
              professional_id: payload.professional.value,
              from: payload.from,
              to: payload.to,
              expenses: {
                companyComission: Number(payload.companyComission) / 100,
                groomingSupplies: priceUnmask(payload.groomingSupplies || ''),
                blueZone: {
                  multiplier: Number(payload.blueZoneQuantity),
                  value: priceUnmask(payload.blueZoneValue || ''),
                },
                parking: {
                  multiplier: Number(payload.parkingQuantity),
                  value: priceUnmask(payload.parkingValue || ''),
                },
                equipment: priceUnmask(payload.equipmentValue || ''),
              },
              otherExpenses: payload.otherExpenses,
            },
          },
        });

        if(veterinarian){
          await generateProfessionalVeterianrianReportPdf({
            logoPath: backgroundPath,
            payload,
            data,
            finantial: selectedProfessionalReport.value === 2,
          });
        } else {
          await generateProfessionalReportPdf({
            logoPath: backgroundPath,
            payload,
            data,
            finantial: selectedProfessionalReport.value === 2,
          });
        }


        launchToast('Relatório gerado com sucesso!', 'success');
      } catch (error: any) {
        launchToast(error.message, "error");
      } finally {
        setLoading(false)
      }
    }

    const handleSubmit = async () => {
      switch (selectedProfessionalReport.value) {
        case 1:
          return geratePDFToProfessional(payload?.isVet || false);
        case 2:
          return geratePDFToProfessional(payload?.isVet || false);
      }
    }

    return (
        <>            
        <Modal visible={isOpen} closeModal={onClose} width={500}>
            <Styles.header>
                <Text
                    text="Selecione o relatório"
                    color={colors.argon.darkBlue}
                    fontFamily="Open Sans"
                    size={17}
                    weight="600"
                />
            </Styles.header>
            <Styles.FormDiv>
              <Form
                onSubmit={console.log}
                ref={selectProfessionalReportFormRef}
                id={SelectProfessionalReportFormId}
              >
                <Styles.rowButton>
                  <Select
                    name="professional"
                    options={options}
                    
                    placeholder="Selecione"
                    onChange={(e) => setSelectedProfessionalReport(e)}
                  />
                </Styles.rowButton>
              </Form>
            </Styles.FormDiv>
            <Styles.rowButton>
                <Button text="Fechar" onClick={onClose} loading={loading}/>
                <Button text="Gerar Relatório" onClick={handleSubmit} loading={loading} />
            </Styles.rowButton>
        </Modal>
        </>
    )
}

export default SelectProfessionalModal