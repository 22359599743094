import colors from "shared/utils/constants/colors";
import styled from "styled-components";

export const FormDiv = styled.div`
  width: 100%;
`;

export const row = styled.div`
  @media (min-width: 650px) {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    width: 100%;
    margin-left: 14px;
    margin-bottom: 24px;
  }
`;


export const rowButton = styled.div <{ margin?: string }>`
display: flex;
justify-content: space-around;
margin-top: 24px;
width: 100%;
back

@media (min-width: 650px) {
  flex-direction: row;
  align-items: flex-end ;
  width: 400px;
  margin-left:${props => `${props?.margin}%` || "70%"};
}
`;

export const rowButtonModal = styled.div <{ margin?: string }>`
display: flex;
justify-content: space-around;
margin-top: 24px;
width: 400px;
back

@media (min-width: 650px) {
  flex-direction: row;
  align-items: flex-end ;
  width: 400px;
  margin-left:${props => `${props?.margin}%` || "70%"};
}
`;

export const rowAddButton = styled.div <{ margin?: string }>`
display: flex;
justify-content: flex-start;
margin-top: 24px;
width: 100%;
back

@media (min-width: 650px) {
  flex-direction: row;
  align-items: flex-end ;
  width: 400px;
  margin-left:${props => `${props?.margin}%` || "70%"};
}
`;


export const field = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  margin-right: 20px;
  margin-top: 20px;
`;


export const MainContainer = styled.main`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-height 100%;
	overflow-x hidden;
`;

export const header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 24px;
`;