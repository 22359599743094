import Modal from 'components/Modal';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import * as Styles from './constants/styles';
import Text from 'components/Text';
import colors from 'shared/utils/constants/colors';
import Button from "components/Button";
import { GET_REPORT_COST, SET_REPORT_COST } from './graphql/ProfessionalReportQuery';
import { useMutation, useQuery } from '@apollo/client';
import { Form } from '@unform/web';
import { Label } from 'components/Label/styles';
import InputRef from 'components/InputRef';
import { FormHandles } from '@unform/core';
import { priceMask, priceMaskInput, priceUnmask } from './professionalReportUtils';
import { UpdateReportCostFormValidationSchema } from './CreateProfessionalReportFormValidations';
import { launchToast } from 'shared/utils/launchToast';
import getValidationErrors from 'shared/utils/getValidationErrors';
import { ValidationError } from 'yup';


export type IUpdateReportCostModal = {
    isOpen: boolean;
    onClose: () => void;
};

type IUpdateReportCostPayload = {
    waterValue: string;
    gasValue: string;
    oilValue: string;
};

const UpdateReportCostModal: React.FC<IUpdateReportCostModal> = ({ isOpen, onClose}) => {

    const [isLoagin, setIsLoagins] = useState<boolean>(false);
    const [waterValue, setWaterValue] = useState<string>('R$ 0,00');
    const [gasValue, setGasValue] = useState<string>('R$ 0,00');
    const [oilValue, setOilValue] = useState<string>('R$ 0,00');
    const defineCostReportReportFormRef = useRef<FormHandles>(null);
    const defineCostReportFormId = 'define-cost-report-form';
    const { data, loading, refetch } = useQuery(GET_REPORT_COST);
    const [setReportCost] = useMutation(SET_REPORT_COST);

    const handleSubmit = useCallback(
        async (
            payload: IUpdateReportCostPayload,
        ) => {
            try {
                await UpdateReportCostFormValidationSchema.validate(
                    {
                        waterValue: payload.waterValue,
                        gasValue: payload.gasValue,
                        oilValue: payload.oilValue,
                    },
                    {
                      abortEarly: false,
                    }
                );
            } catch (error) {
                const errors = getValidationErrors(error as ValidationError);
                defineCostReportReportFormRef.current?.setErrors(errors);
                launchToast('Verifique o preenchimento dos dados', 'error');
            }
            try {
                setIsLoagins(true);
                await setReportCost({
                          variables: {
                            input:       {
                                water: priceUnmask(payload.waterValue),
                                gas:  priceUnmask(payload.gasValue),
                                generator_oil:  priceUnmask(payload.oilValue),
                            },
                          },
                        });
                onClose()
                refetch()
                launchToast('Valores definidos com sucesso', 'success')
            } catch (error: any) {
                launchToast(error.message, "error");
            } finally {
                setIsLoagins(false);
            }
        },
        [],
    );

    useEffect(() => {
        if (data) {

            setWaterValue(priceMask(Number(data.reportCosts.water).toFixed(2)));
            setGasValue(priceMask(Number(data.reportCosts.gas).toFixed(2)))
            setOilValue(priceMask(Number(data.reportCosts.generator_oil).toFixed(2)))
        }
    }, [data]);

    return (
        <Modal visible={isOpen} closeModal={onClose}>
            <Styles.header>
                <Text
                    text="Definicições de Custos"
                    color={colors.argon.darkBlue}
                    fontFamily="Open Sans"
                    size={17}
                    weight="600"
                />
            </Styles.header>
            <Form
                onSubmit={handleSubmit}
                ref={defineCostReportReportFormRef}
                id={defineCostReportFormId}
            >
                <Styles.row>
                    <Styles.field>
                        <Label>Valor Água</Label>
                        <InputRef
                        name="waterValue"
                        containerStyle={{ width: '25rem' }}
                        placeholder="Digite o valor"
                        value={waterValue}
                        onChange={(e: any) => setWaterValue(priceMaskInput(e))}
                        />
                    </Styles.field>
                    <Styles.field>
                        <Label> Valor Gás</Label>
                        <InputRef
                        name="gasValue"
                        containerStyle={{ width: '25rem' }}
                        placeholder="Digite o valor"
                        value={gasValue}
                        onChange={(e: any) => setGasValue(priceMaskInput(e))}
                        />
                    </Styles.field>
                    <Styles.field>
                        <Label>Valor Óleo</Label>
                        <InputRef
                        name="oilValue"
                        containerStyle={{ width: '25rem' }}
                        placeholder="Digite o valor"
                        value={oilValue}
                        onChange={(e: any) => setOilValue(priceMaskInput(e))}
                        />
                    </Styles.field>
                </Styles.row>
            </Form>
            <Styles.rowButtonModal>
                <Button text="Cancelar" onClick={onClose} loading={loading || isLoagin}/>
                <Button text="Salvar" type="submit" form={defineCostReportFormId} loading={loading || isLoagin} />
            </Styles.rowButtonModal>
        </Modal>
    )
}

export default UpdateReportCostModal