import { ApolloError, FetchResult, useQuery } from '@apollo/client';
import { FormHandles, Scope } from '@unform/core';
import { Form } from "@unform/web";
import { FormField, FormRow } from 'components/Forms/styles';
import { Label } from 'components/Label/styles';
import Select from 'components/Select';
import TextInput from 'components/TextInput';
import { ICreateReceiptFormData, IProductReceiptFormData } from 'pages/Receipts/constants/types';
import { GET_SUPPLIERS } from 'pages/StockSuppliers/constants/graphQL';
import { ISupplierBase } from 'pages/StockSuppliers/constants/types';
import { GET_WAREHOUSES } from 'pages/StockWarehouses/constants/graphQL';
import { IWarehousesResponse } from 'pages/StockWarehouses/constants/types';
import React, { useMemo, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import getValidationErrors from 'shared/utils/getValidationErrors';
import { launchToast } from 'shared/utils/launchToast';
import { ValidationError } from 'yup';
import { CreateReceiptFormId, CreateReceiptFormValidationSchema } from './constants';
import convertToCurrencyFormat from 'shared/utils/convertToCurrencyFormat';

export type ICreateReceiptsFormProps = {
	onSubmit: (payload: ICreateReceiptFormData) => Promise<FetchResult<any>>,
	products: IProductReceiptFormData[],
	setReceiptProducts: (products: IProductReceiptFormData[]) => void
}

const CreateReceiptsForm: React.FC<ICreateReceiptsFormProps> = ({
	onSubmit,
	products,
	setReceiptProducts,
}) => {

	const { data: suppliersList } = useQuery<{ suppliers: ISupplierBase[] }>(GET_SUPPLIERS);
	const { data: warehousesList } = useQuery<{ warehouses: IWarehousesResponse[] }>(GET_WAREHOUSES);

	const createReceiptsFormRef = useRef<FormHandles>(null);

	const history = useHistory();

	const handleSubmit = async (
		payload: ICreateReceiptFormData,
		options: { reset: () => void }
	) => {
		try {
			delete payload.receipt.totalPrice;
			if (products.length <= 0) throw new Error('Adicione pelo menos um produto');
			payload.receipt.number = Number(payload.receipt.number);
			await CreateReceiptFormValidationSchema.validate(payload, { abortEarly: false });
			const formattedProducts = products.map((product) => ({
				productId: product.productId,
				unityPrice: product.unityPrice,
				resalePrice: product.resalePrice,
				quantity: product.quantity,
			}));
			await onSubmit({ ...payload, products: formattedProducts });
			options.reset();
			createReceiptsFormRef.current?.setErrors({});
      launchToast("Nota registrada com sucesso!", "success");
      history.goBack();
		} catch (error) {
			if (error instanceof ValidationError) {
				const errors = getValidationErrors(error);
				createReceiptsFormRef.current?.setErrors(errors);
				setReceiptProducts([]);
			} else if (error instanceof ApolloError || error instanceof Error) {
				launchToast(error.message, "error");
			} else {
				launchToast("Erro desconhecido. Contate o suporte", "error");
			}
		}
	};

	const receiptTotalPrice = useMemo(() => {
		const totalPrice = products.reduce((acc, curr) => acc + ((curr.quantity as number) * (curr.unityPrice as number)), 0);
		const maskedPrice = convertToCurrencyFormat(totalPrice);
		return maskedPrice;
	}, [products]);

	return (
		<>
			<Form
				onSubmit={handleSubmit}
				ref={createReceiptsFormRef}
				id={CreateReceiptFormId}
				style={{ flexGrow: 1 }}
			>
				<Scope path="receipt">
					<FormRow>
						<TextInput
							label="numero"
							name="number"
							type="number"
							placeholder="Digite o numero da NF"
						/>
						<TextInput
							label="data da emissão"
							name="emissionDate"
							type="date"
						/>
						<TextInput
							label="previsão de entrega"
							name="estimatedDeliveryDate"
							type="date"
						/>
					</FormRow>
					<FormRow style={{ justifyContent: 'center' }}>
						<FormField style={{ width: 'auto' }}>
							<Label>fornecedor</Label>
						<Select
							name="supplierId"
							placeholder="Selecione um fornecedor"
							options={suppliersList?.suppliers.map((supplier) => ({
								label: supplier.name,
								value: supplier.id,
							}))}
							/>
						</FormField>
						<FormField style={{ width: 'auto' }}>
						<Label>destino</Label>
							<Select
								name="warehouseId"
								placeholder="Selecione um destino"
								options={warehousesList?.warehouses.map((warehouse) => ({
									label: warehouse.name,
									value: warehouse.id,
								}))}
							/>
						</FormField>
						<TextInput
							label="valor total da nota"
							name="totalPrice"
							value={receiptTotalPrice}
							disabled
							containerStyle={{ width: 'auto' }}
						/>
					</FormRow>
				</Scope>
			</Form >
		</>
	)
}

export default CreateReceiptsForm
